@font-face {
	font-family: 'icomoon';
 	src: url('./icomoon.eot');
	src: url('./icomoon.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('./icomoon.woff') format('woff'), url('./icomoon.ttf') format('truetype'), url('./icomoon.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-download:before {
	content: "\e900";
  }
  .icon-cancel:before {
	content: "\e901";
  }
  .icon-bars:before {
	content: "\e902";
  }
  .icon-chart:before {
	content: "\e903";
  }
  .icon-arrow_right_alt:before {
	content: "\e904";
  }
  .icon-plus:before {
	content: "\f067";
  }
  .icon-refresh:before {
	content: "\f021";
  }
  .icon-pencil:before {
	content: "\f040";
  }
  .icon-eye:before {
	content: "\f06e";
  }
  .icon-chevron-up:before {
	content: "\f077";
  }
  .icon-chevron-down:before {
	content: "\f078";
  }
  .icon-filter:before {
	content: "\f0b0";
  }
  .icon-caret-down:before {
	content: "\f0d7";
  }
  .icon-caret-up:before {
	content: "\f0d8";
  }
  .icon-trash:before {
	content: "\f1f8";
  }